const SIZE = { sm: 48, md: 64, lg: 96 };

export function Avatar({ img, size = 'md' }) {
  return (
    <div>
      <div
        className="flex items-start justify-center overflow-hidden bg-gray-300 rounded-full select-none"
        style={{ height: SIZE[size], width: SIZE[size] }}
      >
        {img && <img src={img} alt="avatar" />}
      </div>
    </div>
  );
}
