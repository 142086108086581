import { Avatar } from 'components';

export function PersonAvatar({ img, size, name, sub }) {
  return (
    <div className="flex items-center gap-4">
      <Avatar img={img} size={size} />
      <div className="flex flex-col">
        <p className="font-semibold">{name}</p>
        <p className="mt-1 leading-tight">{sub}</p>
      </div>
    </div>
  );
}
