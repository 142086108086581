import camelcase from 'camelcase';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function Input({
  autoComplete,
  defaultValue,
  id,
  label,
  min,
  name,
  onBlur,
  onChange,
  placeholder,
  readOnly,
  required,
  srLabel,
  type,
  value,
}) {
  return (
    <div className={classNames({ 'space-y-1': !srLabel })}>
      <label
        htmlFor={id || camelcase(label)}
        className={classNames('font-semibold', {
          'sr-only': srLabel,
        })}
      >
        {label}
      </label>
      <input
        autoComplete={autoComplete}
        defaultValue={defaultValue}
        id={id || camelcase(label)}
        min={min}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        type={type}
        value={value}
        className={classNames('rounded-lg shadow border-transparent w-full', {
          'bg-gray-200': readOnly,
          'tracking-tighter': type === 'password',
        })}
      />
    </div>
  );
}

Input.propTypes = {
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  srLabel: PropTypes.bool,
  type: PropTypes.oneOf(['date', 'email', 'password', 'tel', 'text', 'time'])
    .isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export { Input };
