import camelcase from 'camelcase';
import { Button, Input, Page, Select, Title } from 'components';
import { useAxios } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { formatOptions, toastErrorMessage } from 'utils';

export function CreateEdit() {
  const { slug } = useParams();
  const history = useHistory();

  const [{ data: person = {}, loading: gettingPerson }] = useAxios(
    { url: `people/${slug}`, withCredentials: true },
    { manual: !slug }
  );

  const [{ data: locations = [], loading: gettingLocations }] = useAxios({
    url: 'locations',
    params: { _sort: 'name' },
    withCredentials: true,
  });

  const [{ loading: savingPerson }, savePerson] = useAxios(
    {
      url: person.id ? `people/${person.id}` : 'people',
      method: person.id ? 'put' : 'post',
      withCredentials: true,
    },
    { manual: true }
  );

  function handleSubmit(e) {
    e.preventDefault();

    const data = new FormData();
    data.append('name', e.target.name.value);
    data.append('employeeId', e.target.employeeId.value);
    data.append('employmentDate', e.target.employmentDate.value);
    data.append('visaExpiryDate', e.target.visaExpiry.value);

    if (person.id) data.append('id', person.id);

    if (days.some((day) => day.value === e.target.restDay.value)) {
      data.append('offDay', e.target.restDay.value);
    }

    const dutyTime = e.target.dutyTime.value;
    if (dutyTime) data.append('dutyTime', dutyTime);

    if (e.target.profilePicture.files.length) {
      data.append('profilePicture', e.target.profilePicture.files[0]);
    }

    if (locations.some((loc) => loc.id === parseInt(e.target.location.value))) {
      data.append('location', e.target.location.value);
    }

    const headers = { 'Content-Type': 'multipart/form-data' };

    const promise = savePerson({ data, headers });
    promise.then(() => history.replace('/drivers'));
    promise.catch(() => {});

    toast.promise(promise, {
      success: 'Saved',
      loading: 'Saving',
      error: toastErrorMessage,
    });
  }

  if (gettingPerson || gettingLocations) return null;

  return (
    <Page>
      <Title>{slug ? `${person.name}` : 'Add New Driver'}</Title>
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="flex justify-center">
          <ImagePicker
            label="Profile picture"
            imgUrl={person.profile_picture}
          />
        </div>
        <Input label="Name" type="text" defaultValue={person.name} required />
        <Input
          label="Employee ID"
          type="text"
          defaultValue={person.employee_id}
          readOnly={!!slug}
          required
        />
        <div className="grid grid-cols-2 gap-4">
          <Input
            label="Employment date"
            type="date"
            defaultValue={person.employment_date}
            required
          />
          <Input
            label="Visa expiry"
            type="date"
            defaultValue={person.visa_expiry_date}
            required
          />
        </div>
        {locations.length > 0 && (
          <Select
            label="Location"
            placeholder="Select"
            selected={person.location?.id}
            options={formatOptions(locations)}
            onChange={() => {}}
          />
        )}
        <div className="grid grid-cols-2 gap-4">
          <Select
            label="Rest day"
            options={days}
            onChange={() => {}}
            selected={person.off_day}
          />
          <Input
            label="Duty time"
            type="time"
            defaultValue={person.duty_time}
          />
        </div>
        <Button disabled={savingPerson}>Save</Button>
      </form>
    </Page>
  );
}

export function ImagePicker({ label, imgUrl }) {
  const [previewing, setPreviewing] = useState(false);
  const previewContainer = useRef();

  function handleFileChange(e) {
    setPreviewing(!!handleImagePreview(e.target.files[0], previewContainer));
  }

  useEffect(() => {
    if (imgUrl) {
      previewContainer.current.style.backgroundImage = `url(${imgUrl})`;
      setPreviewing(true);
    }
  }, [imgUrl]);

  return (
    <>
      <label
        ref={previewContainer}
        htmlFor={camelcase(label)}
        style={{ height: 192, width: 192 }}
        className="flex items-center justify-center bg-gray-200 bg-top bg-cover rounded-full"
      >
        <span
          className={`bg-gray-300 cursor-pointer font-semibold hover:bg-gray-200 leading-none px-3 py-2 rounded text-gray-600 transition-colors ${
            previewing ? 'sr-only' : ''
          }`.trim()}
        >
          {label}
        </span>
      </label>
      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        id={camelcase(label)}
        className="sr-only"
        onChange={handleFileChange}
      />
    </>
  );
}

function handleImagePreview(file, previewRef) {
  if (!file) {
    previewRef.current.style.backgroundImage = '';
    return;
  }

  if (!file.type.startsWith('image/')) return;

  const reader = new FileReader();
  reader.onload = (function () {
    return function (e) {
      previewRef.current.style.backgroundImage = `url(${e.target.result})`;
    };
  })();
  reader.readAsDataURL(file);

  return true;
}

const days = [
  { label: 'Sunday', value: 'Sunday' },
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
];
