import { Button, Input, List, Page, Person, Radio, Title } from 'components';
import { useAxios, useQuery } from 'hooks';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toastErrorMessage } from 'utils';

export function People() {
  const history = useHistory();
  const query = useQuery();

  const [q, setQ] = useState(null);
  const [status, setStatus] = useState(query.get('status'));
  const [visa, setVisa] = useState(query.get('visa'));

  const [{ data = [], error }] = useAxios({
    url: 'people',
    params: {
      _in: q ? ['employee_id', 'name'] : null,
      q,
      sort: 'name',
      status,
      visa,
    },
    withCredentials: true,
  });

  function handleSubmit(e) {
    e.preventDefault();
    setQ(e.target.searchTerm.value.trim() || null);
  }

  if (error) {
    if (error.code === 401) history.replace('/login');
    else toastErrorMessage(error);
  }

  return (
    <Page>
      <header className="flex items-center justify-between">
        <Title>Drivers</Title>
        <Link to="/drivers/create" className="text-blue-600">
          Create New
        </Link>
      </header>
      <div className="flex flex-col gap-8 sm:gap-16 sm:flex-row">
        <div className="order-2 grow">
          <div className="space-y-8">
            <form onSubmit={handleSubmit} className="flex gap-2">
              <div className="grow">
                <Input
                  label="Search Employees"
                  name="searchTerm"
                  placeholder="Search by name or ID"
                  srLabel
                  type="text"
                  onChange={(e) => {
                    if (e.target.value.trim().length === 0) setQ(null);
                  }}
                />
              </div>
              <Button>Search</Button>
            </form>
            <List>
              {data.map((item) => (
                <li key={item.id}>
                  <Person person={item} />
                </li>
              ))}
            </List>
          </div>
        </div>
        <aside className="flex order-1 gap-8 sm:flex-col shrink-0">
          <h1 className="sr-only">Filters</h1>
          <RadioGroup name="Status">
            <Radio
              label="All"
              value="all"
              id="allStatuses"
              checked={status === null}
              name="status"
              onChange={() => setStatus(null)}
            />
            <Radio
              label="On-duty"
              value="on"
              checked={status === 'on'}
              name="status"
              onChange={() => setStatus('on')}
            />
            <Radio
              label="Resting"
              value="off"
              checked={status === 'off'}
              name="status"
              onChange={() => setStatus('off')}
            />
          </RadioGroup>
          <RadioGroup name="Visa">
            <Radio
              label="All"
              value="all"
              id="allVisas"
              checked={visa === null}
              name="visa"
              onChange={() => setVisa(null)}
            />
            <Radio
              label="Expired"
              value="expired"
              checked={visa === 'expired'}
              name="visa"
              onChange={() => setVisa('expired')}
            />
            <Radio
              label="Expiring"
              name="visa"
              checked={visa === '1-month'}
              value="1-month"
              onChange={() => setVisa('1-month')}
            />
          </RadioGroup>
        </aside>
      </div>
    </Page>
  );
}

function RadioGroup({ children, name }) {
  return (
    <section className="space-y-4 leading-none">
      <h2 className="font-semibold">{name}</h2>
      <div className="space-y-3">{children}</div>
    </section>
  );
}
