import camelcase from 'camelcase';
import PropTypes from 'prop-types';

function Radio({ checked, defaultChecked, id, label, name, onChange, value }) {
  return (
    <div className="flex items-center space-x-1.5">
      <input
        type="radio"
        name={name}
        id={id || camelcase(label)}
        value={value || camelcase(label)}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        className="cursor-pointer focus:ring-offset-1"
      />
      <label
        htmlFor={id || camelcase(label)}
        className="cursor-pointer select-none"
      >
        {label}
      </label>
    </div>
  );
}

Radio.propTypes = {
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export { Radio };
