import { Avatar, Page, Title } from 'components';
import { useAxios } from 'hooks';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

export function Person() {
  const { slug } = useParams();
  const [{ data: person, loading, error }] = useAxios({
    url: `people/${slug}`,
    withCredentials: true,
  });

  if (loading) return null;
  if (error) return 'error';

  let start = '';
  let stop = '';

  if (person.duty_time) {
    start = dayjs(`2021-01-01 ${person.duty_time}`).format('h:mm A');
    stop = dayjs(`2021-01-01 ${person.duty_time}`)
      .add(12, 'h')
      .format('h:mm A');
  }

  return (
    <Page>
      <header className="flex items-center gap-4">
        <div className="order-2">
          <Title>{person.name}</Title>
          <p>
            {person.location ? (
              <a href={`/locations/${person.location.slug}`}>
                {person.location.name}
              </a>
            ) : (
              'No location assigned'
            )}
          </p>
        </div>
        <Avatar img={person.profile_picture} size="lg" />
      </header>
      <dl className="grid grid-cols-2 gap-4">
        <div>
          <dt className="font-semibold">Status</dt>
          {dayjs().format('dddd') === person.off_day ? (
            <dd className="font-semibold text-yellow-700">Resting</dd>
          ) : (
            <dd className="font-semibold text-green-800">On-duty</dd>
          )}
        </div>
        <div>
          <dt className="font-semibold">Employment date</dt>
          <dd>{dayjs(person.employment_date).format('MMMM D, YYYY')}</dd>
        </div>
        <div>
          <dt className="font-semibold">Visa expiry</dt>
          <dd>{dayjs(person.visa_expiry_date).format('MMMM D, YYYY')}</dd>
        </div>
        <div>
          <dt className="font-semibold">Off day</dt>
          <dd>{person.off_day || 'No off day'}</dd>
        </div>
        <div>
          <dt className="font-semibold">Duty time</dt>
          <dd>{start ? `${start} - ${stop}` : 'Not set'}</dd>
        </div>
      </dl>
      <Link
        to={`/drivers/${person.slug}/edit`}
        className="button button_primary"
      >
        Edit
      </Link>
    </Page>
  );
}
