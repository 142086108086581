import { Page, Title } from 'components';
import { useAxios } from 'hooks';
import { Link } from 'react-router-dom';

export function Dashboard() {
  const [{ loading, data }] = useAxios({
    url: 'dashboard',
    params: { visa: '1-month' },
  });

  if (loading) return null;

  return (
    <Page>
      <Title srOnly>Dashboard</Title>
      <dl className="grid grid-cols-2 gap-4 sm:grid-cols-4">
        <Card title="Drivers" count={data.people.all} to="/drivers" />
        <Card title="On-duty" count={data.people.on} to="/drivers?status=on" />
        <Card
          title="Resting"
          count={data.people.off}
          to="/drivers?status=off"
        />
        <Card title="Locations" count={data.locations.all} to="/locations" />
        <Card
          title="Expired Visas"
          count={data.people.expired}
          to="/drivers?visa=expired"
        />
        <Card
          title="Expiring Visas"
          count={data.people.expiring}
          to="/drivers?visa=1-month"
        />
      </dl>
    </Page>
  );
}

function Card({ title, count = 0, to = '#' }) {
  return (
    <Link
      to={to}
      className="text-blue-900 transition-shadow card hover:shadow-lg"
    >
      <dt className="font-semibold leading-none">{title}</dt>
      <dd className="mt-3 text-2xl font-semibold leading-none">{count}</dd>
    </Link>
  );
}
