import { useEffect } from 'react';
import classNames from 'classnames';

export function Title({ children, srOnly = false }) {
  useEffect(() => {
    document.title = children;
  }, [children]);

  return (
    <h2 className={classNames('text-2xl font-semibold', { 'sr-only': srOnly })}>
      {children}
    </h2>
  );
}
