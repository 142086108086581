import { CheckIcon } from '@heroicons/react/outline';
import { Button, Input, Page, PersonAvatar, Title } from 'components';
import { useAxios } from 'hooks';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router';
import { toastErrorMessage } from 'utils';

export function CreateEdit() {
  const { slug } = useParams();
  const history = useHistory();

  const [{ data: location = {}, loading: gettingLocation }] = useAxios(
    { url: `locations/${slug}`, withCredentials: true },
    { manual: !slug }
  );

  const [{ data: people = [], loading: gettingPeople }] = useAxios({
    url: 'people',
    params: { _sort: 'name' },
    withCredentials: true,
  });

  const [{ loading: saving }, save] = useAxios(
    {
      url: location.id ? `locations/${location.id}` : 'locations',
      method: location.id ? 'put' : 'post',
      withCredentials: true,
    },
    { manual: true }
  );

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      id: location.id,
      name: e.target.name.value,
      people: [...e.target.querySelectorAll('input[type="checkbox"]')]
        .filter((employee) => employee.checked)
        .map((employee) => employee.id),
    };

    toast
      .promise(save({ data }), {
        loading: 'Saving',
        success: 'Saved',
        error: toastErrorMessage,
      })
      .then(() => history.replace('/locations'))
      .catch(() => {});
  }

  if (gettingLocation || gettingPeople) return null;

  return (
    <Page>
      <Title>{location.id ? `Edit ${location.name}` : 'Create Location'}</Title>
      <form className="space-y-8" onSubmit={handleSubmit}>
        <Input
          label="Name"
          type="text"
          defaultValue={location.name}
          readOnly={!!slug}
          required
        />
        {people.length > 0 && <Employees people={people} location={location} />}
        <Button disabled={saving}>Save</Button>
      </form>
    </Page>
  );
}

function Employees({ people, location }) {
  const [_people, _setPeople] = useState(people);

  function handleChange(e) {
    _setPeople(
      people.filter((person) => {
        return person.name.toLowerCase().indexOf(e.target.value) !== -1;
      })
    );
  }

  return (
    <div className="space-y-6">
      <Input
        label="Employees"
        type="text"
        placeholder="Search"
        onChange={handleChange}
      />
      <div className="space-y-6">
        {_people.map((person) => (
          <Checkbox
            person={person}
            key={person.id}
            selected={!!location.people?.some((p) => p.id === person.id)}
          />
        ))}
      </div>
    </div>
  );
}

function Checkbox({ person, selected }) {
  const [_selected, _setSelected] = useState(selected);

  return (
    <div key={person.id}>
      <input
        type="checkbox"
        id={person.id}
        onChange={(e) => _setSelected(e.target.checked)}
        className="sr-only"
        checked={_selected}
      />
      <label
        htmlFor={person.id}
        className="flex items-center gap-4 cursor-pointer"
      >
        <div>
          <div
            style={{ height: 32, width: 32 }}
            className={`p-1 text-blue-800 transition-colors rounded ${
              _selected ? 'bg-blue-200' : 'bg-gray-300'
            }`}
          >
            <CheckIcon
              className={`transition-opacity ${
                _selected ? 'opacity-100' : 'opacity-0'
              }`}
            />
          </div>
        </div>
        <PersonAvatar
          name={person.name}
          sub={person.location?.name || 'No location assigned'}
          img={`${person.profile_picture ?? ''}`}
        />
      </label>
    </div>
  );
}
