import { Input, List, Page, Title } from 'components';
import { useAxios } from 'hooks';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export function Locations() {
  const [{ data, loading, error }] = useAxios({
    url: 'locations',
    params: { _sort: 'name' },
    withCredentials: true,
  });

  if (loading || error) return null;

  return (
    <Page>
      <header className="flex items-center justify-between">
        <Title>Locations</Title>
        <Link to="/locations/create" className="text-blue-600">
          Create New
        </Link>
      </header>
      <Search data={data} filters={['name']} />
    </Page>
  );
}

function Location({ location: { id, slug, name, people } }) {
  return (
    <li key={id}>
      <Link
        to={`/locations/${slug}`}
        className="transition-shadow card hover:shadow-lg"
      >
        <p className="font-semibold truncate">{name}</p>
        <p className="mt-3">
          {people.length} {people.length > 1 ? 'Drivers' : 'Driver'}
        </p>
      </Link>
    </li>
  );
}

function Search({ data, filters = [] }) {
  const [_data, _setData] = useState(data);

  function handleChange(e) {
    const searchTerm = e.target.value.toLowerCase();

    _setData(
      data.filter((item) => {
        let found = false;
        for (const filter of filters) {
          found = item[filter]?.toLowerCase().indexOf(searchTerm) !== -1;
          if (found) break;
        }
        return found;
      })
    );
  }

  return (
    <div className="space-y-8">
      <Input
        label="Locations"
        labelSrOnly
        type="text"
        placeholder="Search"
        onChange={handleChange}
      />
      <List>
        {_data.map((location) => (
          <Location key={location.id} location={location} />
        ))}
      </List>
    </div>
  );
}
