import { List, Page, Person, Title } from 'components';
import { Button } from 'components/Button';
import { useAxios } from 'hooks';
import toast from 'react-hot-toast';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toastErrorMessage } from 'utils';

export function Location() {
  const history = useHistory();
  const { slug } = useParams();
  const [{ data, loading, error }] = useAxios({
    url: `locations/${slug}`,
    withCredentials: true,
  });

  const [{ loading: destroying }, destroy] = useAxios(
    {
      url: `locations/${slug}`,
      method: 'delete',
      withCredentials: true,
    },
    { manual: true }
  );

  function handleDelete() {
    toast
      .promise(destroy({ data: { id: data.id } }), {
        loading: 'Deleting',
        success: 'Deleted',
        error: toastErrorMessage,
      })
      .then(() => history.replace('/locations'))
      .catch(() => {});
  }

  if (loading) return null;
  if (error) return 'Error';

  return (
    <Page>
      <div className="space-y-4">
        <header>
          <Title>{data.name}</Title>
        </header>
        <div className="flex space-x-2">
          <Link
            to={`/locations/${data.slug}/edit`}
            className="button button_primary"
          >
            Edit
          </Link>
          <Button
            intent="danger"
            onDoubleClick={handleDelete}
            disabled={destroying}
          >
            Delete
          </Button>
        </div>
      </div>
      <section className="space-y-4">
        <h3 className="text-lg font-semibold">Employees</h3>
        <List>
          {data.people.map((person) => (
            <Person
              key={person.id}
              person={{ ...person, location: { name: data.name } }}
            />
          ))}
        </List>
      </section>
    </Page>
  );
}
