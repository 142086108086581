import { authContext } from 'Auth';
import { Button, Input, Page, Title } from 'components';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { toastErrorMessage } from 'utils';

export function Login() {
  const auth = useContext(authContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      email: e.target.username.value,
      password: e.target.password.value,
    };

    const toastMsg = {
      loading: 'Logging in',
      success: 'Logged in',
      error: toastErrorMessage,
    };

    setLoading(true);
    toast
      .promise(auth.login(data), toastMsg)
      .then(() => history.replace('/'))
      .catch(() => setLoading(false));
  }

  return (
    <Page>
      <div className="max-w-sm mx-auto space-y-12">
        <header className="text-center">
          <Title>Login</Title>
        </header>
        <form onSubmit={handleSubmit} className="space-y-6">
          <Input
            label="Username"
            type="text"
            autoComplete="username"
            required
          />
          <Input
            label="Password"
            type="password"
            autoComplete="current-password"
            required
          />
          <div className="pt-4">
            <Button disabled={loading}>Log in</Button>
          </div>
        </form>
      </div>
    </Page>
  );
}
