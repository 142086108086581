import { ProvideAuth } from 'Auth';
import { Nav } from 'components';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'Routes';

function App() {
  return (
    <div className="max-w-5xl mx-auto" style={{ minWidth: 320 }}>
      <ProvideAuth>
        <Router>
          <header>
            <h1 className="sr-only">Base</h1>
            <Nav />
          </header>
          <main>
            <Routes />
          </main>
        </Router>
        <Toaster position="bottom-right" />
      </ProvideAuth>
    </div>
  );
}

export default App;
