import { Link } from 'react-router-dom';

export function Person({ person }) {
  return (
    <Link
      to={`/drivers/${person.slug}`}
      className="flex overflow-hidden transition-shadow bg-white rounded-lg shadow hover:shadow-lg"
    >
      <img
        src={`${person.profile_picture}`}
        alt="Profile"
        className="flex-none object-cover object-top w-20 h-20"
      />
      <div className="flex flex-col justify-center px-4 overflow-hidden">
        <p className="font-semibold truncate">{person.name}</p>
        <p className="truncate">
          {person.location?.name || 'No location assigned'}
        </p>
      </div>
    </Link>
  );
}
