import { ChangePassword, Login } from 'pages/auth';
import { Dashboard } from 'pages/Dashboard';
import {
  CreateEdit as CreateEditLocation,
  Location,
  Locations,
} from 'pages/locations';
import { CreateEdit as CreateEditPerson, People, Person } from 'pages/people';
import { PrivateRoute } from 'PrivateRoute';
import { Route, Switch } from 'react-router-dom';

function Routes() {
  return (
    <Switch>
      <PrivateRoute path="/" exact>
        <Dashboard />
      </PrivateRoute>

      <Route path="/login">
        <Login />
      </Route>

      <PrivateRoute path="/locations" exact>
        <Locations />
      </PrivateRoute>
      <PrivateRoute path="/locations/create">
        <CreateEditLocation />
      </PrivateRoute>
      <PrivateRoute path="/locations/:slug" exact>
        <Location />
      </PrivateRoute>
      <PrivateRoute path="/locations/:slug/edit" exact>
        <CreateEditLocation />
      </PrivateRoute>

      <PrivateRoute path="/drivers" exact>
        <People />
      </PrivateRoute>
      <PrivateRoute path="/drivers/create">
        <CreateEditPerson />
      </PrivateRoute>
      <PrivateRoute path="/drivers/:slug" exact>
        <Person />
      </PrivateRoute>
      <PrivateRoute path="/drivers/:slug/edit" exact>
        <CreateEditPerson />
      </PrivateRoute>

      <PrivateRoute path="/change-password">
        <ChangePassword />
      </PrivateRoute>
    </Switch>
  );
}

export default Routes;
