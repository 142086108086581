import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function Button({ children, intent, disabled, onClick, onDoubleClick }) {
  const [_disabled, _setDisabled] = useState(disabled);

  useEffect(() => {
    _setDisabled(disabled);
  }, [disabled]);

  return (
    <button
      className={classNames('button', {
        button_primary: !intent && !_disabled,
        button_warning: intent === 'warning' && !_disabled,
        button_danger: intent === 'danger' && !_disabled,
        button_disabled: _disabled,
      })}
      disabled={_disabled}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  intent: PropTypes.oneOf(['danger', 'warning']),
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
};

export { Button };
