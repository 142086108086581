import camelcase from 'camelcase';
import classNames from 'classnames';

function Select({
  disabled,
  id,
  label,
  onChange,
  options,
  placeholder,
  required,
  selected,
  srLabel,
}) {
  return (
    <div className={classNames({ 'space-y-1': !srLabel })}>
      <label
        htmlFor={id || camelcase(label)}
        className={classNames('font-semibold', {
          'sr-only': srLabel,
        })}
      >
        {label}
      </label>
      <select
        disabled={disabled}
        id={id || camelcase(label)}
        onChange={onChange}
        required={required}
        defaultValue={selected}
        className="w-full border-transparent rounded-lg shadow"
      >
        {placeholder && <option value="">{placeholder}</option>}
        <Options options={options} />
      </select>
    </div>
  );
}

function Options({ options }) {
  return (
    <>
      {options.map((o, i) => {
        return (
          <option value={o.value || ''} key={i}>
            {o.label}
          </option>
        );
      })}
    </>
  );
}

export { Select };
