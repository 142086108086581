import { authContext } from 'Auth';
import { Button, Input, Page, Title } from 'components';
import { useAxios } from 'hooks';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { toastErrorMessage } from 'utils';

export function ChangePassword() {
  const auth = useContext(authContext);

  const [{ loading }, changePassword] = useAxios(
    { url: 'change-password', method: 'put', withCredentials: true },
    { manual: true }
  );

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      email: e.target.username.value,
      password: e.target.password.value,
    };

    toast.promise(changePassword({ data }), {
      loading: 'Saving',
      success: 'Password changed',
      error: toastErrorMessage,
    });
  }

  return (
    <Page>
      <header>
        <Title>Change Password</Title>
      </header>
      <form onSubmit={handleSubmit} className="space-y-6">
        <Input
          label="Username"
          type="text"
          defaultValue={auth.user.email}
          readOnly
          required
        />
        <Input label="Password" type="password" minLength={8} />
        <Button disabled={loading}>Save</Button>
      </form>
    </Page>
  );
}
