export function formatOptions(arr = []) {
  return arr.reduce((a, c) => {
    return [...a, { label: c.name, value: c.id }];
  }, []);
}

export function toastErrorMessage(err) {
  if (err.response) {
    switch (err.response.status) {
      case 400:
        return 'Incorrect username or password';
      default:
        return `${err.response.status} ${err.response.statusText}`;
    }
  } else {
    return err.message;
  }
}

export function range(start = 0, stop, step = 1) {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  );
}

export function skeletons(n = 1) {
  return range(1, n);
}
