import { useAxios } from 'hooks';
import { createContext, useEffect, useState } from 'react';

export const authContext = createContext();

export function ProvideAuth({ children }) {
  const [user, setUser] = useState(null);
  const [refreshing, setRefreshing] = useState(true);

  const execLogin = useAxios(
    { url: 'login', method: 'post', withCredentials: true },
    { manual: true }
  )[1];

  const execLogout = useAxios(
    { url: 'logout', method: 'post', withCredentials: true },
    { manual: true }
  )[1];

  const refresh = useAxios(
    { url: 'refresh', method: 'post', withCredentials: true },
    { manual: true }
  )[1];

  useEffect(() => {
    refresh()
      .then(({ data }) => setUser(data))
      .catch(() => setUser(null))
      .finally(() => setRefreshing(false));
  }, [refresh]);

  function login(data) {
    return execLogin({ data }).then(({ data }) => setUser(data));
  }

  function logout() {
    return execLogout().then(() => setUser(null));
  }

  if (refreshing) return null;

  return (
    <authContext.Provider value={{ login, logout, user }}>
      {children}
    </authContext.Provider>
  );
}
