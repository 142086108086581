import { authContext } from 'Auth';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';

export function Nav() {
  const auth = useContext(authContext);
  const history = useHistory();
  const [loggingOut, setLoggingOut] = useState(false);

  function handleLogout(e) {
    e.preventDefault();
    if (loggingOut) return;

    const toastMsg = {
      loading: 'Logging out',
      success: 'Logged out',
      error: (e) => {
        if (e.response) {
          return e.response.status;
        } else {
          return e.message;
        }
      },
    };

    setLoggingOut(true);
    toast
      .promise(auth.logout(), toastMsg)
      .then(() => {
        history.replace('/login');
        setLoggingOut(false);
      })
      .catch(() => setLoggingOut(false));
  }

  if (!auth.user) return null;

  return (
    <nav className="mt-4">
      <h2 className="sr-only">Navigation</h2>
      <ul className="flex justify-end gap-6 px-6">
        <Clink to="/">Dashboard</Clink>
        <Clink to="/drivers">Drivers</Clink>
        <Clink to="/locations">Locations</Clink>
        <Clink to="/logout" onClick={handleLogout}>
          Logout
        </Clink>
      </ul>
    </nav>
  );
}

function Clink({ to, children, onClick }) {
  return (
    <li>
      <Link to={to} onClick={onClick} className="font-semibold">
        {children}
      </Link>
    </li>
  );
}
